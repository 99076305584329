@mixin widget-background() {
  background: transparent
    linear-gradient(270deg,#00a5e17d, rgba(0, 124, 169, 0.09)) 0%
    0% no-repeat padding-box;
}

$dashboard-thumbnail-gutter: 8px;
$dashboard-thumbnail-padding-bot: 34px;
$dashboard-darker-background: rgba(255, 255, 255, 0.06);
$sliderArrowWidth: 24px;
$dark-text: #262937;
$popover-background: #d5d7db;

$widgetHorizontalPadding: 0.625rem;

$widgetVerticalPadding: 1.125rem;

#dashboard {
  .assignment-progress {
    background-color: $dashboard-darker-background;
    .popover {
      top: -32px;
      width: auto !important;
      border-radius: 0.02rem;
      .arrow {
        &::after {
          border-top-color: $popover-background;
        }
      }
      .popover-body {
        padding: 0.25rem 0.5rem;
        background-color: $popover-background;
      }
    }
    &.pt-3 {
      .popover {
        top: -16px;
      }
    }
    .progress {
      height: 0.75rem;
      background-color: $white;
      .progress-bar {
        color: $dark-text;
        background: transparent
          linear-gradient(30deg, #80D2F0 0, #00A5E1 100%) 0% 0% no-repeat
          padding-box;
      }
    }
  }
  .onboardingProgress {
    height: 0.5rem;
    background-color: #015d5a;
    .onboardingProgress-bar {
      height: 0.5rem;
      color: $dark-text;
      background: #00A5E1;
    }
  }
  .bannerRow {
    background-color: #0A1421;
  }
  .pageNumber {
    font-size: 1rem;
    text-decoration: underline;
    &.activePage {
      color: $theme-light-blue;
    }
  }
  .dashboard-light-blue-color {
    color: $theme-light-blue;
  }
  .dashboard-light-green-color {
    color: $dashboard-light-green;
  }
  .widgetSection {
    &.collapsed {
      .widgets {
        display: none;
      }
    }
  }
  .widgetRow {
    @include widget-background();
    height: 2.125rem;
    .title {
      color: #a6acb0;
      font-style: italic;
      margin-left: $widgetHorizontalPadding;
    }
  }

  .label {
    font-size: 1rem;
  }

  svg.portal {
    position: fixed;
    top: -185px;
    right: -465px;
    overflow: hidden;
  }

  .widget {
    &.showControls {
      .mobileOverflowScroll {
        .sliderArrow {
          opacity: 0.75;
          &.disabled {
            opacity: 0.25;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.hideControls {
      .mobileOverflowScroll {
        .sliderArrow {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }

    .wrapShuffle {
      padding-bottom: $dashboard-thumbnail-padding-bot;
      width: $dashboard-thumbnail-gutter + $sliderArrowWidth;

      .sliderArrow {
        border: none;
        background: none;
        padding: 0;
        height: 100%;

        &:hover,
        &:focus,
        &:active {
          border: none;
          background: none;
          outline: none;
        }

        width: $dashboard-thumbnail-gutter + $sliderArrowWidth;

        svg {
          width: $sliderArrowWidth;
        }

        &.previous {
          svg {
            transform: rotateY(180deg);
          }
        }
      }
    }

    .mobileOverflowScroll {
      overflow-x: scroll;

      .controlRow {
        .sliderWindow {
          overflow-x: hidden;
          min-width: 500px;

          .previewRow {
            transition: transform 0.25s ease-in-out;
            flex-wrap: nowrap;

            .carouselItem {
              position: relative;
              height: 100%;
              padding-right: $dashboard-thumbnail-gutter;
              padding-left: $dashboard-thumbnail-gutter;

              .carouselItemLink {
                display: block;
                z-index: 1;

                &:hover {
                  .playOverlay {
                    opacity: 1;
                  }
                }
              }

              .belowThumbnail {
                height: $dashboard-thumbnail-padding-bot;
                bottom: 0;
                z-index: 2;

                h5 {
                  bottom: 0;
                }
              }

              .sliderArrow {
                opacity: 0;

                &.next {
                  position: absolute;
                  padding-left: $dashboard-thumbnail-gutter;
                  right: -($dashboard-thumbnail-gutter + $sliderArrowWidth);
                }

                &.previous {
                  position: absolute;
                  padding-right: $dashboard-thumbnail-gutter;
                  left: -($dashboard-thumbnail-gutter + $sliderArrowWidth);
                }
              }

              .playOverlay {
                opacity: 0;
                transition: opacity 0.25s ease-in-out;
                position: absolute;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        overflow-x: auto;

        .previewCol {
          .previewRow {
            padding-left: $dashboard-thumbnail-gutter + $sliderArrowWidth;
            padding-right: $dashboard-thumbnail-gutter + $sliderArrowWidth;
          }
        }
      }
    }

    .select-wrapper {
      select {
        padding-right: 48px;
      }
    }

    .toggleSort {
      svg {
        height: 1.25rem;
      }
    }
  }
  .widget:not(:first-child) {
    .widgetBar {
      @include widget-background();
      height: 2.125rem;
      @include media-breakpoint-up(md) {
        background: none;
        height: 0;
      }
    }
  }

  .widgetWrapper {
    min-height: 100%;
  }

  .widgetBackground {
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    padding: $widgetVerticalPadding $widgetHorizontalPadding;
  }

  .widgetContentBackground {
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    padding: $widgetVerticalPadding $widgetHorizontalPadding;
  }

  .widgetPanel {
    background-color: $dashboard-darker-background;
    padding: $widgetVerticalPadding;
  }

  .widgetTitle {
    color: rgba(166, 172, 176, 1);
    font-family: $font-family-lexend;
  }

  .widgetFooter {
    background: rgba(1, 163, 224, 0.23);
    padding: 0.875rem;
    p {
      color: rgba(255, 253, 253, 1);
      line-height: 1.75rem;
    }
  }

  .donutWrapper {
    max-width: 70%;
  }
}
