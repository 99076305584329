.teams-content {
  padding-left: 15px;
  padding-right: 15px;
  .create-new-team-row {
    padding-bottom: 2rem;
  }
  .team-row {
    @include row-divider();
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    .team-name,
    i {
      @include button-padding-y();
      margin-bottom: 0;
    }
    .delete-btn-col,
    .manage-btn-col {
      @include media-breakpoint-down(sm) {
        padding-top: 1.5rem;
      }
    }
    &.pressed-false {
      .delete-btn-col,
      .manage-btn-col {
        @extend .d-none;
        @extend .d-sm-none;
        @extend .d-md-block;
      }
    }
  }
}

.team-content {
  .add-member-row {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .member-row {
    @include row-divider();
    padding-top: 1.5rem;
    .member-name,
    i {
      margin-bottom: 0;
    }
    &.pressed-false {
      .delete-btn-col,
      .manage-btn-col {
        @extend .d-none;
        @extend .d-sm-none;
        @extend .d-md-block;
      }
    }
    i {
      display: block;
      cursor: pointer;
      &.pressed-true {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .team-member-options {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}
