.edit-area {
  padding: 2rem 5rem 2rem 1rem;
}

.content-results {
  .nav-tabs {
    border: none;
    li {
      font-size: 1.4rem;
      .nav-link {
        color: $white;
        border: none;
        background-color: transparent;
        cursor: pointer;
        &.active, &:focus {
         color: $theme-light-blue;
         border-bottom: 3px solid $theme-light-blue;
         box-shadow: none;
         outline: none;
        }
        &.font-orange {
          &.active, &:focus {
            border-bottom: 3px solid $theme-yellow-highlight;
          }
        }
      }
    }
  }
  .searchEpisodeCourse {
    margin-left: 42px;
  }
}

.selectEpisodeBtn {
  white-space: normal;
  svg {
    display: inline-block;
    margin-top: 0.16rem;
  }
}

.searchField {
  border-bottom: 2px solid $white;
  input {
    background: none;
    border: none;
    color: $white;
    cursor: text;
    text-indent: 0.5rem;
  }
}

.btn-icon {
    @include button-variant(transparent, transparent);

    text-align: center;

    i {
        @extend .d-flex;

        @extend .align-items-center;
    }
}

.removeTeamMateBtn {
  font-weight: 400;
  i {
    margin-left: 15px;
  }
}

.wizard-header {
  &.card-header {
    > .btn, .btn-link {
      padding-left: 0;
      > span {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
  background-color: unset;
}

.wizard-content-row {
    @extend .row;

    .wizard-content-col {
        @extend .col-12;

        @extend .col-lg-10;

        @extend .offset-lg-1;

        >.mode-edit {
          .move-content-mobile-col,
          .move-controls {
            display: none !important;
          }
        }
        .toogle-episodes-open {
          svg {
            transform: rotate(-90deg);
          }
        }

    }

    h6 {
        @extend .mb-0;
    }
}

#content-area-row {
    @extend .row;

    #content-area-col {
        @extend .col-12;

        .module-content-row {
              &:first-child {
                  .move-content-col {
                      @extend .align-items-end;

                      .move-controls {
                          .move-up-btn {
                              display: none;
                          }
                      }
                  }
              }

              &:last-child {
                  .move-content-col {
                      @extend .align-items-start;

                      .move-controls {
                          >.move-down-btn {
                              display: none !important;
                          }
                      }
                  }

                  .move-content-mobile-col {
                      display: none !important;
                  }
              }

            @extend .row;

            @extend .my-2;

            @extend .my-md-4;

            .move-down-btn {
                border-bottom: $border-width $hr-border-color solid;
                @include media-breakpoint-up(md) {
                    border-bottom-color: transparent;
                }
                &:hover {
                    border-bottom-color: $hr-border-color;
                }
              }

            .move-up-btn,
            .move-down-btn {
                @extend .btn;

                @extend .btn-icon;

                @extend .btn-block;

                >i {
                    height: 30px;
                }

                @include media-breakpoint-up(md) {
                    &:hover {
                        border-bottom-color: transparent;
                    }
                    >i {
                        height: initial;
                    }

                    padding: $btn-padding-y-sm $btn-padding-x-sm;
                    font-size: $font-size-sm;
                    line-height: $btn-line-height-sm;
                    border-radius: $btn-border-radius-sm
                }
            }

            .move-content-col {
                @extend .col-12;

                @extend .col-md-1;

                @extend .d-flex;

                @extend .align-items-center;

                .move-controls {
                    @extend .btn-group-vertical;

                    width: 100%;

                    .move-down-btn {
                        @extend .d-none;

                        @extend .d-md-block;
                    }
                }
            }

            .module-content-details-col {
              @extend .col-12;

              @extend .col-sm-9;

              @extend .col-md-8;

            }

            .module-content-time-col {
                @extend .col-12;

                @extend .col-sm-3;

                h6 {
                    @extend .text-right;
                }
            }

            .move-content-mobile-col {
                @extend .col-12;

                @extend .d-md-none;
            }

        }

    }
}
