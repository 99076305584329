.reports-content {
  .btn-link.visualize {
    text-decoration: none;
    &.active {
      color: $theme-light-blue;
    }
  }

  .report-options {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .graphContainer {
    height: 0;
    transition: height 1s ease-in-out;
    .graph,
    .loadingContents {
      visibility: hidden;
    }
  }
  .graphContainer.expanded {
    height: 135px;
    transition: height 1s ease-in-out;
    .graph,
    .loadingContents {
      visibility: visible;
      animation: fadeIn 2s ease-in;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .graphContainer.expanded {
      height: 155px;
    }
  }

  @media (min-width: 992px) {
    .graphBackground {
      background-image: url("../resources/reports-graph.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    .gettingStarted {
      background: rgb(21, 23, 70);
      background: linear-gradient(90deg, rgba(21, 23, 70, 1) 0%, rgba(21, 23, 70, 0.9) 70%, rgba(21, 23, 70, 0) 100%);
    }
    .graphContainer.expanded {
      min-height: 240px;
    }
  }
}
