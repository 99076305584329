.view-content {
  padding-left: 15px;
  padding-right: 15px;
  .user-row {
    @include row-divider();
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    .truncate:first-child {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-options {
      background-color: rgba(255, 255, 255, 0.05);
    }
    .success {
      color: $theme-green-success;
      font-size: 0.875rem;
    }
    .failed {
      font-size: 0.875rem;
    }
  }
}
