.reports {
  .list-group {
    button.list-group-item {
      cursor: pointer;
      &.active {
        background-color: $theme-slate-active;
        border-color: $theme-slate-active;
      }
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #fff;
  }

  .elm-datepicker--container {
    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fff;
      color: #fff;
      border-radius: 0;
      z-index: 9999;
    }
  }

  @media (min-width: 768px) {
    .setMaxWidth {
      max-width: 190px;
    }
  }
}
