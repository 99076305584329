.login {
    // background-color: #0f1d2f;
    &.bg-wrapper {
        position: relative;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        svg.portal {
            position: absolute;
            top: -185px;
            right: -465px;
        }
    }
    .requestTitle {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .container-xl {
        max-width: 1440px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .wall {
        background-color: rgba($color: #354258, $alpha: 0.73);
        .wall-content {
            form {
                .form-field {
                    input {
                        @extend .mt-2;
                        @extend .p-2;
                        width: 100%;
                    }
                }
                input,
                select {
                    font-family: $font-family-lexend;
                    font-weight: 400;
                    border: 0;
                    border-radius: 0;
                    outline: 0;
                    background: none;
                    border-bottom: 2px solid white;
                    color: white;
                    &::placeholder {
                        color: white;
                    }
                }
                .btn {
                    &.login {
                        height: 48px;
                        font-family: $font-family-lexend;
                        font-weight: 700;
                        background-color: $theme-yellow-highlight;
                    }
                }
            }
        }
    }
}

.vh-100 {
    height: 100vh;
}

@media (min-width: 720px) {
    .login {
        .wall {
            .wall-content {
                position: sticky;
                top: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 2550px) {
    .login {
        &.bg-wrapper {
            svg.portal {
                width: 225%;
                top: -60px;
                right: -1590px;
            }
        }
    }
}
