$dropdown-item-color: black !default;
$dropdown-item-bg: white !default;
$dropdown-item-hover-color: white !default;
$dropdown-item-hover-bg: blue !default;

.elm-dropdown-menu {
  z-index: 99;
}

.elm-dropdown-item {
  background-color: $dropdown-item-bg;
  color: $dropdown-item-color;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);

  &:hover {
    color: $dropdown-item-hover-color;
    background-color: $dropdown-item-hover-bg;
  }
}
