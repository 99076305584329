.body {
  font-weight: 300;
}

.preview {
  background-color: #f8f8f6;
  border-radius: 8px;
  border: solid;
  border-width: 2px;
  border-color: #f8f8f6;
  color: $security-slate;
  &.preview-label {
    font-size: 1rem;
    font-weight: 500;
  }
  #header {
    border-radius: 8px 8px 0px 0px;
  }
  .header-right-side {
    svg {
      height: 20px;
      margin-left: 0.75rem;
    }
  }
}

.preview-header-logo {
  height: 36px;
}

.bottom-rule {
  border-bottom: solid;
  border-width: 1px;
  border-color: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
}

.tab {
  font-size: 1.25rem;
  font-weight: 600;
  &.tab-rule {
    border-bottom: solid;
    border-width: 3px;
  }
}

.label {
  font-size: 1rem;
  font-weight: 300;
  &.label-small {
    font-size: 0.75rem;
  }
}

.toggle-background {
  height: 19px;
  width: 35px;
  border-radius: 16px;
  position: relative;
}

.switch {
  height: 15px;
  width: 15px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  right: 2px;
  top: 2px;
}

.checkbox-background {
  border-radius: 0.25rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-width: 1px;
}

.check {
  line-height: 0.5rem;
  svg {
    width: 0.75rem;
  }
}

.popover-wrapper {
  position: relative;
  display: inline-block;

  .popover-content {
    font-size: 0.875rem;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 250px;
    left: calc(-125px + 6px);
    transform: translate(0, 10px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    background-color: #fff;
    padding: 1rem;
    color: $security-slate;

    p:last-child {
      margin-bottom: 0;
    }

    &:before,
    &:after {
      right: calc(50% - 10px);
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

    &:before {
      top: -8px;
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    &:after {
      top: -7px;
      border-bottom-color: #fff;
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }
  }

  &:hover {
    .popover-content {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transform: translate(0, 12px);
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
  }
}

.btn {
  --btn-accent-active-shadow: color-mix(
    in srgb,
    $theme-yellow-highlight 30%,
    white
  );
  border-width: 0.125rem;
  border-radius: var(--wl-border-radius, 0.5rem);
  &:focus {
    box-shadow: 0 0 0 0.25rem var(--wl-btn-accent-active-shadow);
  }
  &.btn-accent-translucent {
    color: $security-slate;
    background-color: color-mix(in srgb, $theme-yellow-highlight 20%, white);
    border-color: $theme-yellow-highlight;

    &:hover,
    &:focus,
    &.btn-check:focus {
      background-color: color-mix(in srgb, $theme-yellow-highlight 25%, white);
    }

    &:focus,
    &.btn-check:focus {
      box-shadow: 0 0 0 0.25rem var(--btn-accent-active-shadow);
    }
  }
  &.btn-navy-outline {
    color: $theme-background;
    border-color: $theme-background;

    &:hover,
    &:focus,
    &.btn-check:focus {
      color: $theme-background;
      background-color: rgba(252, 252, 252, 0.2);
      border-color: $theme-background;
    }

    &:focus,
    &.btn-check:focus {
      box-shadow: 0 0 0 0.25rem $theme-background;
    }
  }
  &.btn-white-outline {
    color: #fff;
    border-color: #fff;

    &:hover,
    &:focus,
    &.btn-check:focus {
      color: #fff;
      background-color: rgba(252, 252, 252, 0.2);
      border-color: #fff;
    }

    &:focus,
    &.btn-check:focus {
      box-shadow: 0 0 0 0.25rem #fff;
    }
  }
}
