.usageOverview {
    height: 100%;
    transition: height 0.25s linear;
    visibility: visible;
    .header,
    .graph {
        opacity: 1;
        transition: opacity 0.5s linear;
    }
}

.usageOverview.collapseGraph {
    height: 0;
    transition: visibility 0.5s linear, height 0.25s linear;
    visibility: hidden;
    .header,
    .graph {
        opacity: 0;
        transition: opacity 0.25s linear;
    }
}

.chartAxisTextY {
    text-anchor: end;
    font-size: small;
    alignment-baseline: central;
}

.chartlegend {
    text {
        alignment-baseline: middle;
        text-anchor: end;
        font-size: small;
    }
}

.dateLabels {
    .chartAxisTextX {
        text-anchor: middle;
        font-size: small;
        alignment-baseline: text-before-edge;
        dominant-baseline: hanging;
    }
}
