$side-nav-width: 109px;
$bottom-nav-width: 64px;
$top-nav-height: 97px;
$side-nav-bg-color: #ffffff0f;
$top-nav-bg-color: #273647;
$bottom-nav-bg-color: #273647;
$active-nav-item-color: #0477ac;

#mainView {
  height: 100vh;
}

#sideNav {
  width: $side-nav-width;
  height: 100vh;
  background-color: $side-nav-bg-color;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  .nav-item {
    .nav-link {
      width: $nav-side-nav-width;
      height: $nav-side-nav-height;
      justify-content: space-around;
      transition: all ease 0.5s;
      transition-property: height, width;
      color: #fff;
      border: 0;
      cursor: pointer;
      svg {
        padding-left: $nav-side-nav-link-padding * .5;
        padding-right: $nav-side-nav-link-padding * .5;
        padding-top: $nav-side-nav-link-padding-vertical * .5;
        padding-bottom: $nav-side-nav-link-padding-vertical * .5;
        transition: padding ease 0.5s;
        height: 40px;
        max-width: 90px;
      }
      font-size: 0.75rem;
      font-family: $headings-font-family;
      padding: 0;
      &:hover,
      &:active,
      &.active {
        background-color: $active-nav-item-color;
        border-right: $nav-border-active;
        border-bottom: $nav-border;
        border-top: $nav-border;
      }
      span {
        margin-top: -1rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

#bottomNav {
  width: 100%;
  height: $bottom-nav-width;
  background-color: $bottom-nav-bg-color;
  z-index: 999;
  overflow-y: hidden;
  overflow-x: auto;
  border-right: $nav-border;
  .nav-item {
    height: $bottom-nav-width;
    .nav-link {
      width: $nav-bottom-nav-width-sm;
      height: $bottom-nav-width;
      justify-content: space-around;
      transition: all ease 0.5s;
      transition-property: height, width;
      svg {
        padding-left: $nav-bottom-nav-link-width-sm * .5;
        padding-right: $nav-bottom-nav-link-width-sm * .5;
        padding-top: $nav-bottom-nav-link-padding-vertical * .5;
        padding-bottom: $nav-bottom-nav-link-padding-vertical * .5;
        transition: padding ease 0.5s;
      }
      font-size: 0.75rem;
      font-family: $headings-font-family;
      padding: 0;
      &:hover,
      &:active,
      &.active {
        background-color: $active-nav-item-color;
        border-right: $nav-border-active;
        border-bottom: $nav-border;
        border-top: $nav-border;
      }
      span {
        margin-top: -0.5rem;
        padding-bottom: 0.5rem;
      }
      @include media-breakpoint-up(sm) {
        width: $nav-bottom-nav-width;
        svg {
          padding-left: $nav-bottom-nav-link-padding * .5;
          padding-right: $nav-bottom-nav-link-padding * .5;
        }
      }
    }
  }
}

#contentContainer {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: $top-nav-height;
  padding-bottom: $top-nav-height;
  @extend .mb-5;
  height: 100vh;
  overflow-y: scroll;
  #topNav {
    background-color: $top-nav-bg-color;
    @supports (backdrop-filter: blur(9px)) or (-webkit-backdrop-filter: blur(9px)) {
      background: #FFFFFF0F 0% 0% no-repeat padding-box;
      backdrop-filter: blur(43px);
      -webkit-backdrop-filter: blur(43px);
    }
    height: $top-nav-height;
    @supports (backdrop-filter: blur(4px)) or
      (-webkit-backdrop-filter: blur(4px)) {
      backdrop-filter: blur(4px);
    }
    .logo {
      width: 100px;
    }
    .select-wrapper select {
      width: 350px;
    }
  }
  @media (min-width: 768px) {
    margin-left: $side-nav-width;
    padding-left: 50px;
    padding-right: 50px;
    #topNav {
      height: $top-nav-height;
      margin-left: $side-nav-width;
    }
  }
}

.grant-details-btn {
  cursor: pointer;
  text-decoration: underline;
}

.form-control-plaintext {
  color: #000000;
}

#helpButton {
  width: 33px;
  height: 33px;
}

.nav-tabs {
  border-bottom: transparent;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
    .nav-link {
      font-size: 1.1rem;
      border-width: 0;
      border-bottom-width: 2px;
    }
    .btn-link:hover,
    .btn-link-underline:hover,
    .btn-link:focus,
    .btn-link-underline:focus,
    .btn-link.focus,
    .focus.btn-link-underline {
      text-decoration: none;
    }
  }
}

#overlayDropdown {
  .dropdown-menu {
    &.show {
      position: fixed;
    }
  }
  z-index: 1035;
  .dropdown-menu {
    top: 0;
    border: none;
    border-radius: 0;
    bottom: 0;
    background-color: $theme-background;
    width: 100%;
    height: 100vh !important;
    padding-top: 0.9375rem;
    .dropdown-item {
      font-size: 1.5rem;
      font-family: $headings-font-family;
      font-weight: 300;
      line-height: 1.8125rem;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      &.active,
      &:active,
      &:hover {
        background-color: darken($theme-background, 10%);
      }
    }
  }
  .selectable-item {
    &.active {
      color: $theme-yellow-highlight;
      text-decoration: none;
    }
    text-decoration: underline;
  }
}

.intercom-lightweight-app-launcher {
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    margin-bottom: 0rem;
  }
}
