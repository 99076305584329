$font-size-xs: ($font-size-base * .8) !default;

.popover {
  max-width: 405px;
  width: 250px !important;
  font-size: $font-size-xs;
  border-radius: 0.3rem;
  color: $yiq-text-dark;
  left: -20px !important;
  @media (min-width: 768px) {
    width: 405px !important;
  }
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  left: 20px !important;
}
