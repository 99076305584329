$white: #ffffff;

$theme-background: #262937;
$theme-text-on-gray: $white;
$theme-input-text: #2e3033;
$theme-red-error: #cc0000;
$theme-yellow-highlight: #f4b43a;
$theme-light-blue: #00a5e1;
$theme-lightest-blue: #b0e9f7;
$theme-teal: #00a5e1;
$theme-slate-active: #3c5267;
$theme-green-success: #6cc24a;
$theme-aci-slate: #262937;
$theme-light-gray: #8d8d8c;
$theme-accessible-teal: #007ca9;
$security-slate: #1b1b1a;

$dashboard-light-blue: #80d2f0;
$dashboard-light-green: #00a5e1;

$theme-colors: (
  "primary": $theme-yellow-highlight,
  "secondary": $theme-text-on-gray,
  "danger": $theme-red-error,
  "info": $theme-light-blue,
);

$body-bg: $theme-background;
$body-color: $theme-text-on-gray;

$link-color: $theme-text-on-gray;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$btn-border-radius: 6px;
$btn-border-radius-lg: 6px;
$btn-border-radius-sm: 6px;

$dropdown-border-radius: 0px;

$progress-border-radius: 0.0625rem;
$progress-bg: $theme-lightest-blue;
$progress-bar-bg: $theme-light-blue;
$progress-height: 0.5rem;

$dropdown-item-hover-bg: $theme-light-blue;
$dropdown-item-color: black;
$dropdown-item-bg: white;
$dropdown-item-hover-color: white;

$grid-gutter-width: 15px;

$card-bg: transparent;
$card-border-color: #1c1c40;
$card-spacer-x: 15px;

$nav-tabs-link-active-color: $theme-text-on-gray;
$nav-tabs-link-active-bg: transparent;

$headings-font-weight: 400;

//New Fonts
$font-family-sans-serif: "lexend", "roboto", Helvetica, Arial, sans-serif;
$headings-font-family: "lexend", "roboto", Helvetica, Arial, sans-serif;
$font-family-lexend: "lexend", "roboto", Helvetica, Arial, sans-serif;
$font-roboto: "roboto", Helvetica, Arial, sans-serif;
$body-font: "roboto", Helvetica, Arial, sans-serif;

//Old Fonts
// $font-family-sans-serif: "adelle-sans", "proxima-nova", Helvetica, Arial,
//   sans-serif;
// $headings-font-family: "proxima-nova", Helvetica, Arial, sans-serif;
// $font-family-lexend: "fira-sans", "adelle-sans", Helvetica, Arial, sans-serif;
$font-weight-light: 200;
$font-weight-normal: 500;

$theme-muted-text: #a6acb1;
$text-muted: $theme-muted-text;
$hr-border-color: $theme-muted-text;

$breadcrumb-divider: "/";
$breadcrumb-padding-x: 0;
$breadcrumb-active-color: $theme-muted-text;
$breadcrumb-divider-color: $theme-muted-text;

$popover-body-color: $theme-background;

$border-color: $hr-border-color;

$nav-tabs-link-hover-border-color: transparent transparent
  $theme-yellow-highlight !default;
$nav-tabs-link-active-border-color: transparent transparent
  $theme-yellow-highlight !default;

$fresh-card-bg: rgba(255, 255, 255, 0.1);
$orange-bar-border: solid 4px $theme-yellow-highlight;

$nav-border-width: 1px;
$nav-border: $nav-border-width solid darken($theme-slate-active, 5%);
$nav-border-active: $nav-border-width solid $theme-slate-active;

$nav-side-nav-link-width: 3.5rem;
$nav-side-nav-link-width-sm: 1.75rem;

$nav-side-nav-link-padding: 3.25rem;
$nav-side-nav-link-padding-sm: 2.5rem;

$nav-side-nav-link-height: 5.25rem;
$nav-side-nav-link-height-sm: 4.25rem;

$nav-side-nav-link-padding-vertical: 0.5rem;

$nav-side-nav-width: $nav-side-nav-link-width + $nav-side-nav-link-padding; // 6.75rem
$nav-side-nav-width-sm: $nav-side-nav-link-width-sm +
  $nav-side-nav-link-padding-sm; // 5.25rem

$nav-side-nav-height: $nav-side-nav-link-height +
  $nav-side-nav-link-padding-vertical; // 5.75
$nav-side-nav-height-sm: $nav-side-nav-link-height-sm +
  $nav-side-nav-link-padding-vertical; // 5.75

$nav-bottom-nav-link-width: 2.25rem;
$nav-bottom-nav-link-width-sm: 2rem;

$nav-bottom-nav-link-padding: 3.5rem;
$nav-bottom-nav-link-padding-sm: 1.75rem;

$nav-bottom-nav-link-height: 3.5rem;
$nav-bottom-nav-link-height-sm: 1.75rem;

$nav-bottom-nav-link-padding-vertical: 0.5rem;

$nav-bottom-nav-width: $nav-bottom-nav-link-width + $nav-bottom-nav-link-padding; // 6.75rem
$nav-bottom-nav-width-sm: $nav-bottom-nav-link-width-sm +
  $nav-bottom-nav-link-padding-sm; // 5.25rem

$nav-bottom-nav-height: $nav-bottom-nav-link-height +
  $nav-bottom-nav-link-padding-vertical; // 5.75
$nav-bottom-nav-height-sm: $nav-bottom-nav-link-height-sm +
  $nav-bottom-nav-link-padding-vertical; // 5.75

html {
  height: 100%;
  min-height: 100%;
}

body {
  background: rgb(30, 45, 63);
  background-image: -webkit-linear-gradient(
    60deg,
    rgba(38, 51, 67, 1) 0%,
    rgba(15, 29, 47, 1) 35%,
    rgba(15, 29, 47, 1) 100%
  );
  background-image: -o-linear-gradient(
    60deg,
    rgba(38, 51, 67, 1) 0%,
    rgba(15, 29, 47, 1) 35%,
    rgba(15, 29, 47, 1) 100%
  );
  background-image: linear-gradient(
    150deg,
    rgba(38, 51, 67, 1) 0%,
    rgba(15, 29, 47, 1) 35%,
    rgba(15, 29, 47, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  background-attachment: fixed;
  letter-spacing: 0.025em;
  overflow: hidden;

  ::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
    scrollbar-width: none;
  }
}

$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

.font-orange {
  color: $theme-yellow-highlight !important;

  &.btn-link {
    &:hover,
    &:active,
    &:visited {
      color: darken($theme-yellow-highlight, 20%) !important;
    }
  }
}

.badge.badge-info {
  background-color: $theme-accessible-teal;
}

.btn {
  &.dropdown-toggle {
    border-radius: $dropdown-border-radius;
  }
}

.font-color-slate {
  color: $theme-aci-slate;
}

.lt-blue-vertical-rule {
  border-left: 2px solid $theme-light-blue;
}

.grant {
  .card-body {
    border-left: 2px solid $theme-yellow-highlight;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

button {
  letter-spacing: 0.025em;
}

@mixin row-divider() {
  border-top: $border-width solid $hr-border-color;
}

@mixin button-padding-y() {
  padding-top: $btn-padding-y;
  padding-bottom: $btn-padding-y;
}

// @import url("https://use.typekit.net/dhh0ftb.css");
@import url("//fonts.googleapis.com/css?family=Lexend:300,400,500,600,700");
@import url("//fonts.googleapis.com/css?family=Roboto:300,400,500,600,700");
@import "npm:bootstrap/scss/bootstrap";
@import "./proportal/elm-dropdown";
@import "./proportal/popover-override";
@import "./proportal/modal-override";
@import "./proportal/text";
@import "./proportal/nav";
@import "./proportal/breadcrumb";
@import "./proportal/elm-datepicker";
@import "./proportal/wizard";
@import "./proportal/teams-tab-content";
@import "./proportal/truncate";
@import "./proportal/users";
@import "./proportal/modals";
@import "./proportal/icons";
@import "./proportal/member-activity";
@import "./proportal/metrics-graph";
@import "./proportal/reports";
@import "./proportal/reports-wizard";
@import "./proportal/login";
@import "./proportal/onboarding";
@import "./proportal/dashboard";
@import "./proportal/settings";

.select-wrapper {
  position: relative;
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
  margin-bottom: 0px;
}

.select-wrapper select {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
    max-width: 300px;
  }
  background-color: $theme-aci-slate !important;
  color: $white !important;
  padding: 3px;
  border: 4px solid #08101a;
  font-size: 18px;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  cursor: pointer;
}

.select-wrapper::before {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  display: block;
  content: url("../resources/caret-down.png");
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 31px;
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  color: $white;
  background-color: $theme-aci-slate;
  pointer-events: none;
  margin: 4px;
  cursor: pointer;
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.tagSelectableContent {
  .btn-link {
    &:focus {
      color: $theme-yellow-highlight;
    }
  }
}

.hiddenOutline {
  &:focus {
    outline: none;
  }
}

.addAdditionalSeat {
  cursor: pointer;
  color: $theme-light-blue;
}

.selectedMember {
  color: $theme-muted-text;
}

.btn-xs {
  @include button-size(0.2rem, 0.5rem, 0.875rem, 1.5, 2px);
}

.btn-link-underline {
  @extend .btn-link;
  text-decoration: underline;
}

.container-custom {
  @extend .container;
  padding: 0;
}

.progress-mt {
  margin-top: ($h5-font-size - $progress-height);
}

.tab-content-padded {
  @extend .tab-content;
  padding: 0.5rem;
}

.icon {
  @extend .align-text-bottom;
  width: $font-size-lg;
}

.breadcrumb {
  background-color: rgba(255, 255, 255, 0);
}

.breadcrumb a {
  cursor: pointer;
  color: #00a3e0 !important;
}

.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      font-style: normal;
    }
  }
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.padding-cancel {
  padding: 5px 40px;
}

.information-icon {
  @extend .ml-2;
  img {
    margin-bottom: 3px;
  }
}

.nav-tabs .nav-link {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.elm-dropdown-item {
  border-radius: 0;
}

.teams-dropdown {
  display: none;
}

.has-teams {
  .teams-dropdown {
    display: block;
  }
}

.move-icon {
  @extend .d-flex;

  @extend .justify-content-center;

  &.secondary-action-fill > svg > g > g {
    fill: $theme-light-blue;
  }
}

.move-icon.move-icon-down {
  > svg {
    transform: rotate(180deg);
  }
}

i {
  &.toggle {
    @include button-padding-y();
    display: block;
  }
}

.custom {
  color: $theme-light-blue;
}

.toggle {
  cursor: pointer;
  &.pressed-true {
    svg {
      transform: rotate(180deg);
    }
  }
  &.rotate-right {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.card {
  border: none;
}

button {
  &.btn.btn-link {
    &.draggable {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }
}

.cadenceSelect {
  background-color: #0f1d2f;
  color: #ffffff;
}

.applyToAll {
  background-color: rgba(252, 252, 252, 0.1);
  .applyToAllHeader {
    text-align: left;
    font-weight: 600;
  }
}

.pro-card-row {
  @extend .row;
  @extend .pb-4;
  .pro-card {
    border: none;
    .card {
      border: none;
    }
    @include media-breakpoint-down(xs) {
      background-color: unset;
      .card {
        background-color: unset;
      }
    }

    @extend .col-12;

    @extend .card;

    @extend .pt-3;

    @extend .pb-4;

    .pro-card-title-row {
      @extend .row;

      .pro-card-title-col {
        @extend .col-12;

        @extend .col-sm-9;

        @extend .col-md-10;
      }

      .pro-card-action-col {
        @extend .col-12;

        @extend .col-sm-3;

        @extend .col-md-2;
      }
    }

    .pro-card-bottom-row {
      @extend .row;

      @extend .mt-4;

      @extend .justify-content-end;

      .pro-card-bottom-col {
        @extend .col-12;

        @extend .col-sm-4;

        @extend .col-md-3;
      }
    }
  }
}

.table-header {
  .sort-toggle {
    svg {
      width: 14px;
      height: auto;
    }
    &.active {
      svg {
        fill: $theme-light-blue;
      }
    }
  }
  .rotate {
    svg {
      transform: rotate(180deg);
    }
  }
}
.bannerRow {
  background: rgb(18, 26, 36);
  background: linear-gradient(
    90deg,
    rgba(18, 26, 36, 0) 0%,
    rgba(10, 20, 33, 1) 15%,
    rgba(10, 20, 33, 1) 50%,
    rgba(10, 20, 33, 1) 85%,
    rgba(37, 47, 59, 0) 100%
  );
  .bannerImage {
    .image {
      height: 100%;
      background-image: url("../resources/cutBanner-01.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.closeBanner {
  cursor: pointer;
}

.waitCircle {
  fill: $white;
  animation: kaboom 1s ease-in-out none infinite;
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
  }
}

@keyframes kaboom {
  90% {
    transform: scale(0.8);
    transform-origin: center center;
  }
  100% {
    transform: scale(1);
    transform-origin: center center;
  }
}

.row {
  &.row-full-width {
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
  }
}

.toggleBtn {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 100;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  &.selectAllToggle {
    .default {
      color: $white;
    }
    .inactive {
      color: $theme-light-gray;
    }
  }

  &::before {
    content: "";
    width: 50%;
    height: 100%;
    background-color: $theme-light-gray;
    border-radius: 2px 0px 0px 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .off {
    margin-right: 5px;
    position: relative;
    z-index: 2;
    color: $theme-light-gray;
    &.active {
      color: $white;
    }
  }

  .on {
    margin-left: 5px;
    position: relative;
    z-index: 2;
    color: $theme-light-gray;
    &.active {
      color: $theme-aci-slate;
    }
  }

  &.onState::before {
    left: 50%;
    border-radius: 0px 2px 2px 0px;
    background-color: $theme-teal;
  }
}

.descriptionToggle {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.topicTitle {
  h5 {
    font-weight: 600;
  }
}
