.resetIcon {
  margin-top: -0.25rem;
}

#hamburgerIcon,
#searchIcon {
  height: 1.5rem;
}
#navToggleMobileMenu {
  border: none;
}