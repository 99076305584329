$onboarding-panel-toggle-z-index: 1033;
$onboarding-panel-z-index: 1030;

$onboarding-panel-closed-width: 8px;
$onboarding-panel-open-width: 400px;
$onboarding-panel-sm-scrn-open-width: 300px;

#onboardingPanel {
  width: $onboarding-panel-closed-width;
  top: $top-nav-height;
  right: 0;
  background-color: #0e1b2b;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.3);
  z-index: $onboarding-panel-z-index;

  h5,
  h6 {
    margin-bottom: 0.25rem;
  }

  .onboardingContent {
    width: $onboarding-panel-sm-scrn-open-width;
  }

  &.open {
    width: $onboarding-panel-sm-scrn-open-width;
    transition: width 1s ease;

    .onboardingContent {
      overflow-y: auto;
      visibility: visible;
      opacity: 1;
      transition: opacity 2s;
    }
  }

  &.closed {
    width: $onboarding-panel-closed-width;
    transition: width 1s ease;
  }

  .iconDarkBl {
    height: 18px;
    width: 18px;
  }

  .headerText {
    color: #a6acb0;
    font-family: $font-family-lexend;
  }

  .completedStep {
    text-decoration: line-through;
    color: #a6acb0;
  }

  .incompletedStep,
  .completedStep {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .skippedText {
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
  }

  .titleText {
    font-size: 1.2rem;
    font-weight: normal;
  }

  .descriptionText {
    font-family: $font-family-sans-serif;
    font-weight: 100;
    font-style: italic;
  }

  .onboardingToggle {
    z-index: 999;
    top: $top-nav-height + 0.5;

    .onboardingMobilePanelOverlay {
      width: $onboarding-panel-closed-width;
      top: $top-nav-height;
      transition: right 1s ease;
    }

    &.open {
      right: $onboarding-panel-sm-scrn-open-width;
      transition: right 1s ease;

      .onboardingMobilePanelOverlay {
        right: $onboarding-panel-sm-scrn-open-width - 8px;
        background-color: #0e1b2b;
      }
    }

    &.closed {
      right: $onboarding-panel-closed-width;
      transition: right 1s ease;

      .onboardingMobilePanelOverlay {
        right: 0;
        background-color: #0e1b2b;
      }
    }

    .halfCircle {
      position: relative;
      z-index: 1000;
      height: 50px;
      width: 25px;
      border-radius: 90px 0 0 90px;
      background: #0e1b2b;
      box-shadow: -0.25px 0.5px 5px 0px rgba(0, 0, 0, 0.3);
      padding: 9px 2px 9px 9px;
      cursor: pointer;

      svg {
        transition: transform 0.5s ease 0.5s;
        transform: rotateY(0deg);
      }
    }

    &.toggleRotate {
      .halfCircle {
        padding: 9px 0px 9px 11px;
      }

      svg {
        transition: transform 0.5s ease 0.5s;
        transform: rotateY(180deg);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  #onboardingPanel {
    .onboardingContent {
      width: $onboarding-panel-open-width;
    }

    &.open {
      width: $onboarding-panel-open-width;
    }

    .onboardingToggle {
      &.open {
        right: $onboarding-panel-open-width;

        .onboardingMobilePanelOverlay {
          right: $onboarding-panel-open-width - 8px;
        }
      }
    }
  }
}
