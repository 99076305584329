.metricsRow {
    background-color: $fresh-card-bg;
    padding-bottom: 1rem;
    .metricsCard {
        height: 100%;
        .metricText {
            font-size: 1.25rem;
        }
        .metricTitle {
            font-size: 1.5rem;
            font-family: 'lexend', sans-serif;
            font-weight: 600;
            color: #A6ACB0;
            line-height: 1.6rem;
        }
    }
}

.memberAssociations, .memberAssignments {
    h2 {
        margin-bottom: 0;
        small {
            font-size: 1.25rem;
        }
    }
    ul {
        border-top: $orange-bar-border;
        a {
            font-weight: 400;
            li {
                font-size: 1.2rem;
            }
        }
    }
}

.pace {
    font-size: 2rem;
    color: #A6ACB0;
    font-weight: 700;
    @media (min-width: 720px) { 
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.hrsLabel {
    margin-top: -6px;
}

.progress {
    height: 0.8rem;
    background-color: rgba(255, 255, 255, 0.1);
    .progress-bar {
      background: transparent
        linear-gradient(30deg, #80D2F0 0, #00A5E1 100%) 0% 0% no-repeat
        padding-box;
    }
  }

  .completionsTitle {
    font-weight: 600;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }


@media (min-width: 720px) { 
    .metricsRow {
        background-color: unset;
        >div {
            &:first-child {
                .metricsCard {
                    padding-left: 0;
                }
            }
            &:last-child {
                .metricsCard {
                    padding-right: 0;
                }
            }
        }
        .metricsCard {
            background-color: $fresh-card-bg;
            padding-left: 5px;
            padding-right: 5px;
            background-clip: content-box;
            .metricText {
                font-size: 22px;
            }
        }
    }
    .memberAssociations, .memberAssignments {
        ul {
            border-left: $orange-bar-border;
            border-top: none;
        }
    }
}

@media (min-width: 1920px) {
    .metricsCard {
        .metricText {
            font-size: 1.3rem;
        }
    }
}

@media (min-width: 2550px) {
    .metricsCard {
        .metricTitle {
            font-size: 1.6rem;
        }
        .metricText {
            font-size: 1.5px;
        }
    }
}